import React from 'react'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { I18n } from '@front/volcanion/'

import List from './List'

const HistorySection = ({ disabled, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('history.label', { count: 1 })}</Typography>
  </StepLabel>
  {!disabled &&
    <StepContent >
      <Grid container spacing={1}>
        <List {...props} />
      </Grid>
    </StepContent>
  }
</>

export default React.memo(HistorySection)
