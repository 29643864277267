import React, { useMemo, useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'
import info from '../info'
import config from '../constants'
import StorageManager from '@front/squirtle/services/storage'
import Provider from '@front/volcanion/provider'
import { ThemeProvider } from '@mui/material/styles'
import IdentityUtils from '@front/squirtle/utils/identity'
import getTranslations from './translations'
import { SVG, GIF, Image, Loader, SnackbarProvider } from '@front/squirtle'
import Contact from "@front/volcanion/classes/contact"

import CssBaseline from "@mui/material/CssBaseline"
I18n.setI18nConfig(getTranslations)

import useBlobHandler from '@front/squirtle/hooks/useBlobHandler'
import useNotification from '@front/squirtle/hooks/useNotification'
import useRouter from '@front/squirtle/components/Router/useRouter'

import Pages from './pages'

const OrangeContact = new Contact(null, 'orange', true, { disable_retry: true })
OrangeContact.init('api-mediation.flexiblecontactcenter.orange-business.com', 443, {
  'Content-Type': 'application/json',
  'Accept-Language': 'fr',
}, { axios: { withCredentials: true } })

const overrides = {
  MuiInputLabel: {
    defaultProps: {
      size: 'small',
      shrink: true,
    }
  },
}
const Main = () => {
  const identity = hooks.useIdentity()
  const isReady = hooks.useReadyStatus()
  const [mode] = hooks.useGlobalState('mode')
  const theme = useMemo(() => IdentityUtils.getTheme(identity, mode, overrides), [identity, mode, overrides])
  const CLIENT_COMMERCIAL_NAME = hooks.useRelayConstant('CLIENT_COMMERCIAL_NAME')

  useEffect(() => {
    document.title = _.compact([CLIENT_COMMERCIAL_NAME]).join('')
  }, [CLIENT_COMMERCIAL_NAME])

  return (
    <Loader isLoading={!isReady} nativeLoader>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Pages />
        </SnackbarProvider>
      </ThemeProvider>
    </Loader >
  )
}

const MemoMain = React.memo(Main)
const App = () => {
  return (
    <Provider
      storage={{ whitelist: [], manager: StorageManager }}
      relay={{ info, config }}
      model={{
        auto_load: [
          { model_name: 'setting' },
          { model_name: 'pagecategory' },
          { model_name: 'pagegroup' },
          { model_name: 'commercialpackage' },
          { model_name: 'commercialformula' },
          { model_name: 'application' },
          { model_name: 'domain' },
          { model_name: 'useroption' },
          { model_name: 'vehicleoption' },
          { model_name: 'billingcategory' },
          { model_name: 'inboundcall' },
          { model_name: 'event', params: { filter: { active: 1 } } }
        ]
      }}
      global={{ persist: ['persist'] }}
      main={{
        useRouter,
        useNotification,
        useBlobHandler,
        ImageRenderer: Image,
        SVGRenderer: SVG,
        GIFRenderer: GIF,
      }}
    >
      <MemoMain />
    </Provider>
  );
};

export default React.memo(App);
export {
  OrangeContact
}
