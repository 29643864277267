import React from 'react'

import { Grid, Step, } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import GroupTab from '@abra/panels/Tab/Group'

import PrimarySection from './Section/Primary'
import OptionsSection from './Section/Options'
import HistorySection from './Section/History'

const Vehicle = ({ isManagingRecord, group_id }) =>
  <Grid container item xs={10} justifyContent='center' rowSpacing={3}>
    <Grid item xs={12} hidden={!group_id}> <GroupTab value={3} /> </Grid>
    <Grid item xs={12}>
      <FormHeader />
      <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical" getStepProps={index => ({ active: index !== 2 || !!isManagingRecord })}>
        <Step> <PrimarySection /> </Step>
        <Step> <OptionsSection /> </Step>
        <Step> <HistorySection disabled={!isManagingRecord} /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(Vehicle)
