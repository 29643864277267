import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { record_id, group_id } = props
  const formProps = {
    name: 'vehicle_form',
    model_name: 'vehicle',
    record_id,
    related_states: [],
    related_searches: [],
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(group_id), [group_id]),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    populate: ['info.model', 'options'],
    initialState: { isReadOnly: !!record_id, group_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    modifyChangesOnly: false
  }
  const mergedProps = {}
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
